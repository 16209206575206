import React from 'react';

export function isRequiredText(value) {
    return value ? undefined : <span className='error'>This field is required</span>;
} 

export function isRequiredTextSignup(value) {
    return value ? undefined : <span className='error signup'>This field is required</span>;
}

export function isRequiredSelection(value) {
    return value ? undefined : <span className='error'>This selection is required</span>;
}

export function isRequiredStateSelection(value) {
    return value ? undefined : <span className='error state-selection'>This selection is required</span>;
}

export function isRequiredOptionalTextCustom(value) {
    return value ? undefined : <span className='optional-question-error'>Please provide additional information</span>;
}

export function isRequiredDate(value) {
    return value ? undefined : <span className='error'>This date is required</span>;
}

export function isRequiredSignature(value) {
    return value ? undefined : <span className='error signature-error'>This signature is required</span>;
}

export function isRequiredSelectionCheckbox(value) {
    return value ? undefined : <span className='checkbox-error'>A selection is required</span>;
}

export function isRequiredQuestionSelection(value) {
    return value ? undefined : <span className='error question-error'>This selection is required</span>;
}

export function validateEmail(value) {
    // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(value) {
        return re.test(String(value).toLowerCase()) ? undefined : <span className='error'>Invalid email provided</span>;
    } else {
        return <span className='error'>Invalid email provided</span>;
    }
}

export function validateEmailSignup(value) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(value) {
        return re.test(String(value).toLowerCase()) ? undefined : <span className='signup error'>Invalid email provided</span>;
    } else {
        return <span className='signup error'>This field is required</span>;
    }
}

export function isRequiredPhoneNumber(value) {
    if(value) {
        var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        return phoneNumberPattern.test(value) ? undefined : <span className='error'>Invalid phone number provided</span>;
    } else {
        return <span className='error'>Invalid phone number provided</span>;
    }

}

export function conditionallyRequiredDate(condition) {
    return condition ? isRequiredDate : () => {};
};

export function conditionallyRequiredSelection(condition) {
    return condition ? isRequiredSelection : () => {};
};

export function conditionallyRequiredEmail(condition) {
    return condition ? validateEmail : () => {};
};

export function conditionallyRequiredEmailSignup(condition) {
    return condition ? validateEmailSignup : () => {};
};

export function conditionallyRequiredPhoneNumber(condition) {
    return condition ? isRequiredPhoneNumber : () => {};
};

export function conditionallyRequiredText(condition) {
    return condition ? isRequiredText : () => {};
}

export function conditionallyRequiredSignature(condition) {
    return condition ? isRequiredSignature : () => {};
};

export function falseValidation(condition) {
    return true;
};