import React, { Component, createRef } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Step1 from './signup_steps/Step1';
import Wizard from './helpers/signupWizard';
import $ from 'jquery';

class SignupForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            submitted: false,
            pdfUrl: false,
            SignupFormItems: null,
            drp: false,
            userType: 'patient'
        }
    }

    setSelectedUser(userType) {
        this.setState((prevState) => {return {...prevState, userType: userType}});

        if(userType === 'patient'){
            $('.patient-btn').css('background-color','#0062cc');
            $('.patient-btn').css('border-color','#0062cc');
            $('.caregiver-btn').css('background','#1b93bc');
            $('.caregiver-btn').css('border-color','#1b93bc');
        }else  if(userType === 'caregiver'){
            $('.patient-btn').css('background','#1b93bc');
            $('.patient-btn').css('border-color','#1b93bc');
            $('.caregiver-btn').css('background-color','#0062cc');
            $('.caregiver-btn').css('border-color','#0062cc');
        }else{
            $('.patient-btn').css('background','#1b93bc');
            $('.patient-btn').css('border-color','#1b93bc');
            $('.caregiver-btn').css('background-color','#1b93bc');
            $('.caregiver-btn').css('border-color','#1b93bc');
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const emailAddress = urlParams.get('emailAddress');
        this.setState({
            ...this.state,
            emailAddress: emailAddress ? emailAddress : null,
            drp: document.getElementById('sign-up-form') ? true : false
        });
    }

    onSubmit = async (values) => {
     //   console.log('Submit Sign Up Form');
       
        const reactState = this;
        const { drp } = reactState.state;
        let url = "/";
        if(process.env.NODE_ENV === "development") {
          url = "http://localhost:8000/"
        }
        values.formType = 'signUp';
        values.guid = false;
        values.userType = this.state.userType;
       // console.log('values.userType='+ JSON.stringify(this.props));
        values.indication = drp ? 'Dementia' : 'Parkinsons';
        //console.log(JSON.stringify(values));
        try {
            await Axios({
                method: 'post',
                url: url + "api/signup",
                data: values,
                headers: {
                    'Content-type': 'application/json'
                }
            })
            .then(function (res) {
              reactState.setState({
                ...reactState.state,
                submitted: true
              });
            });
        }
        catch(errors) {
        //    console.log("errors", errors)
        }
        reactState.scrollToTop();
    }

    scrollToTop = ()=> {
		this.toTop.current.scrollIntoView({ behavior: "smooth" }); 
	}

    toTop = createRef();

    render() {
        const { submitted, emailAddress, drp } = this.state;

        return (
            <div className="acadia-form drp-signup-form" ref={this.toTop}>
                <Wizard onSubmit={this.onSubmit} noOfPages={0} submitted={submitted} toTop={this.toTop}>
                    <Wizard.Page>
                        <Step1 emailAddress={emailAddress} drp={drp} setSelectedUser={(userType)=>this.setSelectedUser(userType)}/>
                    </Wizard.Page>
                </Wizard>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
  const { questionOptions } = state;
  return { questionOptions: questionOptions };
}

export default connect(mapStateToProps, actions)(SignupForm);