import { QUESTION, RESET } from './types';

export const questionAnswer = (name, value) => async dispatch => {
  try {
    dispatch({ type: QUESTION, payload: { name, value } });
  } catch (errors) {
    // dispatch({ type: SET_ERRORS, payload: { error: "Unable to get current step" }});
  }
};

export const questionReset = () => async dispatch => {
  try {
    dispatch({ type: RESET, payload: null });
  } catch (errors) {
    // dispatch({ type: SET_ERRORS, payload: { error: "Unable to get current step" }});
  }
};