import * as React from "react";

const Header = (props) => {
  const showBackButton = props.position >= 0 && !props.thankyou;
  return (
    <div className="ddg-header">
      <div className={`col-12 d-flex ${!showBackButton ? 'justify-content-end' : 'justify-content-between'} align-items-center mb-3 pr-0`}>
        {showBackButton &&
          <a
            href="history.back()"
            className="btn-back"
            onClick={(e) => {
              e.preventDefault();
              props.changePosition("back", false);
            }}
          >
            {props.contentfulContent.button_back}
          </a>
        }
        <button className="btn-close d-flex justify-content-center align-items-center" data-dismiss="modal" aria-label="close button">
          <span className="btn-close__icon">
            <span></span>
            <span></span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default Header;
