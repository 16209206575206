import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form-html5-validation';
import * as actions from '../../actions';
import { isRequiredText, validateEmailSignup } from '../helpers/Validation';


class Step1 extends Component {
    render() {
        const { emailAddress } = this.props;
        
        return (
            <Fragment>
                <div className="row cont-fluid-spacer desktop">&nbsp;</div>
			    <div className="row cont-fluid-spacer-mobile mobile">&nbsp;</div>
			    
                <div class="row">
                    <div class="col">
                        <p>Fill out the form below to receive information about Parkinson&#8217;s disease (PD)&#8211;related hallucinations and delusions. You will hear
                            from caregivers and other people living with these symptoms. We&#8217;ll also send you information about a potential treatment option.
                            Remember, only your doctor can determine if a treatment option is right for you.
                        </p>
                        <p className="mb-0">All fields are required.</p>
                        <div className="row cont-fluid-spacer desktop">&nbsp;</div>
                        <div className="row cont-fluid-spacer-mobile mobile">&nbsp;</div>
                        <p className="mb-0">Which best describes you?</p>
                    </div>
                </div>
                <div className="row desktop" style={{height: '30px'}}>&nbsp;</div>
                <div className="row cont-fluid-spacer-mobile mobile">&nbsp;</div>
                
                <div className="row">
					<div className="col-6 col-md-3 pl-3 mt-1">
						<button onClick={() => this.props.setSelectedUser('patient')} className="btn btn-primary patient-btn" ole="button">I am living with PD</button>
					</div>
					<div className="col-6 col-md-3 pl-3 mt-1">
						<button onClick={() => this.props.setSelectedUser('caregiver')} className="btn btn-primary caregiver-btn" ole="button">I am a caregiver</button>
					</div>
				</div>
                <div className="row desktop" style={{height: '30px'}}>&nbsp;</div>
			    <div className="row cont-fluid-spacer-mobile mobile">&nbsp;</div>
                

            
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Field
                            className="form-control full-width-input"
                            name="firstName"
                            component="input"
                            type="text"
                            validate={isRequiredText}
                        >
                            {({ input, meta }) => (
                                <Fragment>
                                    <label className="text-left full-width full-width-input signup-label">First name {meta.error && meta.touched && meta.error}</label>
                                    
                                    <input className="form-control full-width-input signup-input" {...input} />
                                </Fragment>
                            )}
                        </Field>
                    </div>
                    <div className="cont-fluid-spacer-mobile mobile">&nbsp;</div>
                    <div className="col-12 col-md-6">
                        <Field 
                            className="form-control full-width-input"
                            name="lastName"
                            component="input"
                            type="text"
                            validate={isRequiredText}
                        >
                            {({ input, meta }) => (
                                <Fragment>
                                    <label className="text-left full-width full-width-input signup-label">Last name {meta.error && meta.touched && meta.error }</label>
                                    <input className="form-control full-width-input signup-input" {...input} />
                                </Fragment>
                            )}
                        </Field>
                    </div>
                </div>

                <div className="row" style={{height: '20px'}}>&nbsp;</div>
                
                <div className="row">
                    <div className="col">
                        <div className="form-group relative">
                            <Field 
                                className="form-control full-width-input"
                                name="emailAddress"
                                component="input"
                                type="text"
                                defaultValue={emailAddress}
                                validate={validateEmailSignup}
                            >
                                {({ input, meta }) => (
                                    <Fragment>
                                        <label className="text-left full-width full-width-input signup-label">Email {meta.error && meta.touched && meta.error }</label>
                                        <input className="form-control full-width-input signup-input" {...input} placeholder="Example: name@email.com" />
                                    </Fragment>
                                )}
                            </Field>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-md-9">
                        <p>By clicking &ldquo;Sign up now,&rdquo; I understand and agree to the <a href="https://www.acadia-pharm.com/privacy/" className="signup-terms-and-privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://www.acadia-pharm.com/terms/" className="signup-terms-and-privacy" target="_blank" rel="noopener noreferrer">Terms of Use</a>. I&nbsp;agree to receive communications from Acadia&nbsp;Pharmaceuticals Inc.</p>
                    </div>
                </div>

            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    const { questionOptions } = state;
    return { questionOptions: questionOptions };
}

export default connect(mapStateToProps, actions)(Step1);
