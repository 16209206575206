import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from "final-form-arrays";
import createDecorator from 'final-form-focus';
import Loader from 'react-loader-spinner';

const focusOnErrors = createDecorator();

class PageWizard extends Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired
	}
	
	static Page = ({ children }) => children;

	constructor(props) {
		super(props)
		this.state = {
			page: 0,
			values: props.initialValues || {},
			validForm: false,
		}
	}

	next = values =>
		this.setState(state => ({
			page: Math.min(state.page + 1, this.props.children.length - 1),
			values
		})
	);

  	previous = () => {
		this.setState(state => ({
			page: Math.max(state.page - 1, 0)
		}));
		// this.props.toTop.current.scrollIntoView({ behavior: "smooth" });
	};

	handleSubmit = values => {
		const { children, onSubmit } = this.props;
		const { page } = this.state;
		const isLastPage = page === React.Children.count(children) - 1;
		if (isLastPage) {
			return onSubmit(values);
		} 
		else {
			this.next(values);
		}
	}

	render() {
		const { children, noOfPages, submitted, drp } = this.props;
		const { page, values, validForm } = this.state;
		const activePage = React.Children.toArray(children)[page];
		const isLastPage = page === noOfPages;
		return (
			<Form
				initialValues={values}
				onSubmit={this.handleSubmit}
				decorators={[ focusOnErrors ]}
				mutators={{
					...arrayMutators
				}}
			>
				{({ form, handleSubmit, submitting, values }) => (
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="">
								<div>
									{submitted && (
										<div class="container-fluid white-bg">
											<div class="row cont-fluid-spacer desktop">&nbsp;</div>
											<div class="row cont-fluid-spacer-mobile mobile">&nbsp;</div>
											<div class="container">
												<div class="row">
														<div class="col">
															<h2>Your enrollment is now complete</h2>
															<p>Thank you for signing up. Our team is currently working on improvements to our email program. Keep an eye out for news and information regarding PD&#8211;related hallucinations and delusions. We'll send the information you requested as soon as possible.</p>
															<div class="row cont-fluid-spacer desktop">&nbsp;</div>
															<div class="row cont-fluid-spacer-mobile mobile">&nbsp;</div>
															
															
															<div id="sign-up-submit-btn" class="row">
																<div class="col col-md-3 pt-3">
																	<a href="/" className="btn btn-primary btn-return-home" ><b>Return to the homepage</b></a>
																</div>
															</div>
															
															
														</div>
												</div>
											</div>
											<div class="row cont-fluid-spacer desktop">&nbsp;</div>
											<div class="row cont-fluid-spacer-mobile mobile">&nbsp;</div>
										</div>
									)}
									{!submitted && (
										<Fragment>
											{activePage}
										</Fragment>
									)}
									{isLastPage && !submitted && (
										<div id="sign-up-submit-btn" class="row">
											<div className="col col-md-3 pt-3">
												<FormSpy
													subscription={{ valid: true }}
													onChange={props => {
														this.setState({
															...this.state,
															validForm: props.valid
														});
													}}
												/>
												<button className={"btn btn-primary btn-sign-up" + (drp ? " drp-button" : " pdp-button") + (submitting ? "loading" : " " + (validForm ? " valid-submit" : " "))} type="submit" disabled={submitting}>
													<b>Sign up now</b>
													{submitting && (
														<Loader
															type="ThreeDots"
															color="#FFF"
															height={30}
															width={100}
															className="loading-icon"
															style={{display: "inline-block",
																position: "relative",
																top: "8px",
																left: "15px"}}
														/>
													)}
												</button>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
						{process.env.NODE_ENV === "development" && (
							<pre style={{marginTop: "100px", marginBottom: "50px"}}>{JSON.stringify(values, 0, 2)}</pre>
						)}
					</form>
				)}
			</Form>
		)
	}
}

const mapStateToProps = (state) => {
    const { currentStep } = state;
    return { currentStep: currentStep };
}

export default connect(mapStateToProps, actions)(PageWizard);
