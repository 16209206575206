import * as React from 'react';

const Navigation = (props) => {

  let Dots = []; 

  for (var i = 0; i < props.length ; i++) {
    Dots.push(
      <span className={`dot ${i === props.position ? 'active' : ''}`} key={i}></span>
    )
  }

  //console.log(props.position)
  
  if(props.position === -1 || props.position === props.length){
    return (
      <></>
    )
  }else{
    return (
      <nav>
        {Dots}
      </nav>
    )
  }
}

export default Navigation;