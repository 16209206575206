import { QUESTION, RESET } from '../actions/types';

const initialState = {
  seenHallucinations: false,
  falseBeliefs: false,
  experienceAffectingActivities: false,
  emailAddress: false,
  agreeToTerms: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case QUESTION:
      return Object.assign({}, state, {
        ...state,
        [action.payload.name]: action.payload.value
      });
    case RESET:
      return initialState;
    default:
      return state;
  }
}