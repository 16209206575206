import * as React from 'react';

const SecondaryContent = (props) => {

  const returnTitlebuttons = () => {
    if (props.endOfShow) return;

    if (props.position === -1) {
      return (
        <>
          <h2>{props.contentfulContent.intro_question}</h2>
        </>
      )
    }
    if (props.section === 'patient' && props.position > -1) {
      return (
        <>
          <div className="images">
            <div className="images__container d-flex justify-content-center justify-content-lg-start">
              {props.content.images.map((img, index) =>
                <img src={`https:${img}`} key={index} alt='' />
              )}
            </div>
          </div>
          <h2 className='small'>{props.content.patient_statement}</h2>
        </>
      )
    }
    if (props.section === 'caregiver' && props.position > -1) {
      return (
        <>
          <div className="images">
            <div className="images__container d-flex justify-content-center justify-content-lg-start">
              {props.content.images.map((img, index) =>
                <img src={`https:${img}`} key={index} alt='' />
              )}
            </div>
          </div>
          <h2 className='small purple'>{props.content.caregiver_statement}</h2>
        </>
      )
    }
  }

  const returnButtons = () => {
    if (props.position === -1) {
      return (
        <div className={'ddg-secondary-content__container d-flex flex-column ' + props.section}>
          <button className='ddg-button' onClick={() => props.setSection("patient")}>{props.contentfulContent.intro_button_patient}</button>
          <button className='ddg-button' onClick={() => props.setSection("caregiver")}>{props.contentfulContent.intro_button_caregiver}</button>
        </div>
      )
    }
    if (props.endOfShow && !props.thankyou) {
      return (
        <div className={'ddg-secondary-content__container d-flex flex-column ' + props.section}>
          <button className='ddg-button' onClick={() => props.generatePDF('email')} disabled={!props.enableEmailBtn}>{props.contentfulContent.slide_end_email_guide}</button>
          <button className='ddg-button' onClick={() => props.generatePDF('print')} disabled={!props.enablePrintBtn}>{props.contentfulContent.slide_end_skip_and_print}</button>
        </div>
      )
    } 
    if (props.endOfShow && props.thankyou) {
      return (
        <div className={'ddg-secondary-content__container d-flex flex-column align-items-center ' + props.section}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.nuplazid.com"
            className='ddg-button learnAboutTreatmentDDGButton'
          >{props.contentfulContent.slide_thankyou_treatment_option}</a>
          <button className="start-over" onClick={() => props.startOver()}>{props.contentfulContent.slide_thankyou_start_over}</button>
        </div>
      )
    } 
    if (props.position === 1 && props.section === "patient") {
      return (
        <>
          <div className='ddg-secondary-content__container ddg-secondary-content__container--small-gap d-flex flex-column '>
            <button className="ddg-button" onClick={() => {
              props.hallucinationsPerMonth('1-15')
              props.changePosition("forward", false)
            }}>{props.contentfulContent.patient_slide_2_answer_a}</button>
            <button className="ddg-button" onClick={() => {
              props.hallucinationsPerMonth('15-30')
              props.changePosition("forward", false)
            }}>{props.contentfulContent.patient_slide_2_answer_b}</button>
            <button className="ddg-button" onClick={() => {
              props.hallucinationsPerMonth('30+')
              props.changePosition("forward", false)
            }}>{props.contentfulContent.patient_slide_2_answer_c}</button>
            <button className="ddg-button" onClick={() => {
              props.hallucinationsPerMonth('0')
              props.changePosition("forward", false)
            }}><span dangerouslySetInnerHTML={{__html: props.contentfulContent.patient_slide_2_answer_d}}></span></button>
          </div>
        </>
      )
    }
    if (props.position >= 0) {
      return (
        <div className={'ddg-secondary-content__container d-flex flex-column flex-sm-row ' + props.section}>
          <button className="ddg-button" onClick={() => props.changePosition("forward", true)}>{props.contentfulContent.button_yes}</button>
          <button className="ddg-button" onClick={() => props.changePosition("forward", false)}>{props.contentfulContent.button_no}</button>
        </div>
      )
    }
  }

  return (
    <>
      <div className='ddg-secondary-content col-12 col-lg-4 offset-lg-1 mb-3 d-flex flex-column justify-content-center align-items-center align-items-lg-start'>
        {returnTitlebuttons()}
        <div className={props.section}>
          {returnButtons()}
        </div>
      </div>
    </>
  )
}

export default SecondaryContent;