import { SHOW_OTHER } from '../actions/types';

const initialState = {
  experiencedOther: false,
  experiencedOtherTwo: false,
  experiencedOtherThree: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_OTHER:
      return action.payload
    default:
      // Form starts on step 1
      return false;
  }
}